<template>
  <v-row class="mx-0 my-0 py-0 mxw-900 buttons_row">
    <template v-if="!blocked">
      <v-btn ref="btnRowBackBtn" outlined :to="backTo" color="primary" v-bind="backAttrs" v-on="backOn">
        {{ $t('back') }}
      </v-btn>

      <slot name="left"></slot>

      <v-spacer></v-spacer>

      <slot name="right"></slot>

      <v-btn ref="btnRowNextBtn" color="primary" v-bind="frontAttrs" v-on="frontOn" :to="frontTo" v-if="!isNextVisible">
        {{ $t('next') }}
      </v-btn>
    </template>

    <template v-else>
      <v-spacer></v-spacer>

      <v-btn ref="btnRowStartAgainBtn" color="primary" outlined class="mx-2" :to="nextTo"
        >{{ $t('startAgainBtn') }}
      </v-btn>
    </template>
  </v-row>
</template>

<script>
import { routerNamesConst } from '@/lib/consts/routerNames.const';
import { SelectBtnMixin } from '@/lib/mixins/SelectBtnMixin';
import { eventBusConst } from '@/lib/consts/eventBus.const';

export default {
  props: ['backTo', 'backAttrs', 'backOn', 'frontTo', 'frontAttrs', 'frontOn', 'blocked', 'isNextVisible'],
  mixins: [SelectBtnMixin],

  data: () => ({
    nextTo: { name: routerNamesConst.SCAN },
  }),
  created() {
    this.$bus.$on(eventBusConst.btn.row.next.focus, () => {
      if (this.$refs.btnRowNextBtn) this.$refs.btnRowNextBtn.$el.focus();
    });
    this.$bus.$on(eventBusConst.btn.row.back.focus, () => {
      if (this.$refs.btnRowBackBtn) this.$refs.btnRowBackBtn.$el.focus();
    });
    this.$bus.$on(eventBusConst.btn.row.again.focus, () => {
      if (this.$refs.btnRowStartAgainBtn) this.$refs.btnRowStartAgainBtn.$el.focus();
    });
  },
};
</script>

<style lang="scss">
.mxw-900 {
  width: inherit;
  max-width: 900px;
}

.buttons_row {
  @media (max-width: 700px) {
    flex-direction: column;
    gap: 10px;

    .ml-2 {
      margin-left: 0 !important;
    }
    .mr-2 {
      margin-right: 0 !important;
    }
  }
}
</style>
