<template>
  <v-toolbar flat color="transparent" width="100%">
    <v-app-bar-nav-icon>
      <v-img src="@/assets/images/navbar/logo_mini.png" min-width="45" max-width="45" alt="" />
    </v-app-bar-nav-icon>

    <div class="title ml-2 no-wrap">CRM LOUNGE</div>

    <v-spacer></v-spacer>

    <v-btn icon @click="onClick">
      <v-icon>{{ value ? 'mdi-menu' : 'mdi-backburger' }}</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { MobileSizeMixin } from '@/lib/mixins/MobileSizeMixin';
import { mapActions, mapWritableState } from 'pinia';
import { useAppSettingsStore } from '@/store/settings.store';

export default {
  mixins: [MobileSizeMixin],
  props: ['value'], // is mini
  emits: ['update:drawner'],
  computed: {
    ...mapWritableState(useAppSettingsStore, { blocked: 'isNavBlocked' }),
  },
  methods: {
    ...mapActions(useAppSettingsStore, ['setNavBlocked']),
    onClick() {
      this.setNavBlocked(!this.value);
      this.isMobileSize && this.$emit('update:drawner', false);
      this.$emit('input', this.isMobileSize ? false : !this.value);
    },
  },
};
</script>

<style scoped>
.no-wrap {
  white-space: nowrap;
}
</style>
