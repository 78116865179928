import { defineStore } from 'pinia';

import { profile } from '@/api/login.api';
import { globalSetTimeoutConst } from '@/lib/consts/globalSetTimeout.const';
import { SERVICE_NAME } from '@/lib/consts/serviceName.const';

const _getPointsNames = (points) => points.map((x) => `${x.airport} [${x.pointName}]`).join(', ');

export const useProfileStore = defineStore('profile-store', {
  persist: true,

  state: () => ({
    firstName: '',
    lastName: '',
    role: '',
    points: [],
    isLoading: false,
    username: '',
    permission: [],
  }),

  getters: {
    pointsTitle: (state) => _getPointsNames(state.points),
    pointsId: (state) => state.points.map((point) => point.point_id),
    name: (state) => `${state.firstName} ${state.lastName}`,
    isAuthenticated: (state) => state.name !== ' ',
    userRole: (state) => state.role,
    userPermission: (state) => state.permission?.[0] ?? SERVICE_NAME.ONPASS,
    adminPermissions: (state) => state.permission,
  },

  actions: {
    clear() {
      this.firstName = '';
      this.lastName = '';
      this.role = '';
      this.points = [];
      this.username = '';
      this.permission = [];
    },

    getPointsNamesById(ids) {
      const points = !ids?.length ? this.points : this.points.filter((p) => ids.includes(p.point_id));
      return _getPointsNames(points);
    },

    getProfileData(d) {
      if (d?.user) this.username = d.user;

      this.isLoading = true;
      return profile()
        .then((v) => {
          const data = v.data?.data?.profile;

          this.firstName = data?.first_name || '';
          this.lastName = data?.last_name || '';
          this.role = data?.group_tag || '';
          this.permission = data?.service_type;

          this.points =
            data?.points.map((item) => ({
              point_id: item.point_id,
              airport: item.airport,
              pointName: item.point_name,
              description: item.description_short,
              img: item.photo_path,
            })) || [];

          return v.data;
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false;
          }, globalSetTimeoutConst);
        });
    },
  },
});
