<template>
  <v-card outlined flat>
    <v-card-title class="border-line">
      <span class="card-header subtitle-1 card-header-m">
        <span :class="titleClasses || '' + ' font-weight-bold'">{{ title }}</span>
        <span :class="`ml-4 ml-4-m ${subTitleClasses || ''}`">{{ subTitle }}</span>
      </span>

      <slot name="title"></slot>
    </v-card-title>

    <v-divider />

    <slot></slot>
  </v-card>
</template>

<script>
export default {
  props: ['title', 'titleClasses', 'subTitle', 'subTitleClasses'],
};
</script>

<style lang="scss">
.border-line::before {
  content: '';
  background-color: #04a9f5;
  position: absolute;
  left: 0;
  width: 4px;
  height: 20px;
}

@media screen and (max-width: 568px) {
  .card-header-m {
    display: block;

    span {
      display: block;
    }

    .ml-4-m {
      margin-left: 0 !important;
    }
  }
}
</style>
