import { defineStore } from 'pinia';

import { useProfileStore } from './profile.store';

import { setLanguage } from '@/api/login.api';

export const Locales = {
  ru: 'ru',
  en: 'en',
};

export const version = process.env.VUE_APP_VERSION;

export const useAppSettingsStore = defineStore('settings-store', {
  persist: true,

  state: () => ({
    locale: null,
    isNavBlocked: true,
    isUserChangeNav: false,
    version,
    isDesktop: undefined,
  }),

  actions: {
    async setLocale(locale) {
      const profile = useProfileStore();
      if (profile.isAuthenticated) await setLanguage(locale);
      this.locale = locale;
    },
    setNavBlocked(v) {
      this.isNavBlocked = v;
      this.isUserChangeNav = true;
    },
    setDesktopToggle(v) {
      this.isDesktop = v;
    },
    setUpdateVersion(v) {
      this.version = v;
    },
  },
});
