export const fatalErrorConst = new Set([500, 90, 30, 31, 13]);

const msg = {
  network_error: {
    ru: 'Нет соединения',
    en: 'No connection',
  },
  30: {
    ru: 'Внутренняя ошибка стороннего сервиса',
    en: 'Internal error of a third-party service',
  },
  default: {
    ru: 'Ошибка на стороне сервера',
    en: 'Error on the server side',
  },
};

export const errorMsg = (msg_type, lang = 'ru') => {
  return msg?.[msg_type]?.[lang] ?? msg.default[lang];
};
