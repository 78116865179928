import { useProfileStore } from '@/store/profile.store';
import axios from 'axios';
import { eventBusConst } from '@/lib/consts/eventBus.const';
import { customEventHelper } from '@/lib/helpers/customEvent.helper';
import { errorMsg, fatalErrorConst } from '@/lib/consts/fatalError.const';
import { useAppSettingsStore } from '@/store/settings.store';
import { logger } from '@/lib/logger';

axios.defaults.withCredentials = true;

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL || '',
  timeout: 360 * 1000,
  withCredentials: true,
});

const EXCLUDE_URL = new Set([
  '/login',
  '/profile',
  '/logout',
  '/report',
  '/statistic',
  '/cancel_task',
  '/operator_log',
]);

http.interceptors.request.use(
  function (config) {
    if (!EXCLUDE_URL.has(config.url)) {
      logger.info({
        url: config.url,
        params: config.params,
        method: config.method,
        data: config.data,
      });
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function errorHandler(findError, msg_type) {
  const settingsStore = useAppSettingsStore();
  const textMsg = errorMsg(msg_type, settingsStore.locale.toLowerCase());
  const msg = `${findError}: ${textMsg}`;
  customEventHelper(eventBusConst.toast.show, { text: msg });
}

http.interceptors.response.use(
  function (response) {
    if (!EXCLUDE_URL.has(response.config.url)) {
      logger.info({
        data: response.data,
        url: response.request.responseURL,
      });
    }
    if (response.data.responseCode !== undefined && response.data.responseCode !== 0) {
      const profileStore = useProfileStore();
      if (response.data.responseCode === 22 && profileStore.isAuthenticated) {
        profileStore.clear();
      }

      const findError =
        fatalErrorConst.has(response.data.responseCode) || fatalErrorConst.has(response.status)
          ? response.data.responseCode || response.status
          : false;
      findError && errorHandler(findError);
      return Promise.reject(response.data);
    }
    return response;
  },
  function (error) {
    logger.error(error);
    errorHandler(500, 'network_error');
    return Promise.reject(error);
  }
);

export default http;
