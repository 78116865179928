<template>
  <v-row align="center">
    <v-col cols="12" sm="12" md="6">
      <point-select
        :value="pointId"
        @input="(v) => $emit('update:pointId', v)"
        v-bind="{ ...$attrs, ...(selectAttrs || {}) }"
        :disabled="disabled"
      />
    </v-col>
    <v-col cols="12" sm="12" :md="!times ? 6 : 12">
      <date-picker-pair
        :value="dates"
        @input="(v) => $emit('update:dates', v)"
        :input-attrs="$attrs"
        :picker-attrs="pickerAttrs || {}"
        :left-input-attrs="leftInputAttrs ?? {}"
        :right-input-attrs="rightInputAttrs ?? {}"
        :show-time="showTime"
        :time-value="times"
        @timeupdate="(v) => $emit('update:times', v)"
        :disabled="disabled"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: [
    'dates',
    'pointId',
    'selectAttrs',
    'pickerAttrs',
    'showTime',
    'times',
    'disabled',
    'leftInputAttrs',
    'rightInputAttrs',
  ],
};
</script>
