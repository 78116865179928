<template>
  <div></div>
</template>

<script>
export default {
  methods: {
    keyUp(ev) {
      this.$emit('keyup', ev);
    },

    keyDown(ev) {
      this.$emit('keydown', ev);
    },
  },

  created() {
    window.addEventListener('keyup', this.keyUp);
    window.addEventListener('keydown', this.keyDown);
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.keyUp);
    window.removeEventListener('keydown', this.keyDown);
  },
};
</script>

<style lang="scss" scoped>
div {
  display: none;
}
</style>
