<template>
  <v-toolbar flat color="transparent" width="100%">
    <v-row justify="center" class="pb-4">
      <v-img src="@/assets/images/navbar/powered.svg" max-width="160" alt="" v-show="!mini" />
    </v-row>
  </v-toolbar>
</template>

<script>
export default {
  props: ['mini'],
};
</script>
