export const generateTimeArrayHelper = () => {
  const clockArray = [];
  for (let i = 0; i < 24; i++) {
    clockArray.push(`${i.toString().length === 1 ? '0' + i : i}`);
  }

  const minutesArray = [];
  for (let i = 0; i < 60; i++) {
    minutesArray.push(`${i.toString().length === 1 ? '0' + i : i}`);
  }

  return {
    hours: clockArray,
    minutes: minutesArray,
  };
};
