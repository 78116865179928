<template>
  <div>
    <NavigationDrawerVue @update:drawner="(v) => (drawner = v)" :drawner="drawner" />
    <v-app-bar color="bars" v-if="isMobileSize" dark>
      <v-app-bar-nav-icon @click.stop="drawner = !drawner"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div><v-img src="@/assets/images/navbar/menu-btn.svg" min-width="150" max-width="150" contain /></div>
    </v-app-bar>
    <v-main>
      <v-container class="container-mobile" fluid>
        <PointInfoCard />
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useAppSettingsStore } from '@/store/settings.store';
import { useOrdersStore } from '@/store/orders.store';

import NavigationDrawerVue from './components/navbar/NavigationDrawer.vue';
import PointInfoCard from '@/layouts/main/components/PointInfoCard.vue';
import { MobileSizeMixin } from '@/lib/mixins/MobileSizeMixin';

export default {
  mixins: [MobileSizeMixin],
  components: {
    PointInfoCard,
    NavigationDrawerVue,
  },

  data: () => ({
    drawner: false,
  }),

  computed: {
    ...mapState(useAppSettingsStore, ['locale']),
    ...mapState(useOrdersStore, ['qr']),
  },

  methods: {
    ...mapActions(useOrdersStore, ['scan']),
  },

  watch: {
    locale() {
      if (this.qr !== '') this.scan(this.qr);
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 568px) {
  .container-mobile {
    padding: 0;
  }
}
</style>
