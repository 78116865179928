import DateLib from '@/lib/Date';

export const getProfileStore = () => {
  const req = window.localStorage.getItem('profile-store');
  try {
    return JSON.parse(req);
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getVersionApp = () => {
  const req = window.localStorage.getItem('settings-store');
  try {
    return JSON.parse(req);
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const logSend = {
  setData: () => {
    try {
      const data = DateLib().format('DD-MM-YYYY');
      window.localStorage.setItem('log', data);
    } catch (e) {
      console.log(e);
      return undefined;
    }
  },
  getData: () => {
    try {
      window.localStorage.getItem('log');
    } catch (e) {
      console.log(e);
      return undefined;
    }
  },
};
