<template>
  <v-app id="main" :style="{ background: $vuetify.theme.themes[theme].background }">
    <router-view />
    <toast-item />
  </v-app>
</template>

<script>
import cookieCheck from '3rd-cookie-check';
import { login, logout } from '@/api/login.api';
import ToastItem from '@/components/ToastItem.vue';
import DateLib from '@/lib/Date';
import { BROADCAST_CHANNEL_NAME } from '@/lib/consts/BroadcastChannelConst';
import { eventBusConst } from '@/lib/consts/eventBus.const';
import { routerNamesConst } from '@/lib/consts/routerNames.const';
import { userRoleConst } from '@/lib/consts/userRole.const';
import db from '@/lib/db';
import { customEventHelper } from '@/lib/helpers/customEvent.helper';
import { TabletSizeMixin } from '@/lib/mixins/TabletSizeMixin';
import { logSend } from '@/plugins/localStorage';
import { useLoginFormStore } from '@/store/login-form.store';
import { useOrdersStore } from '@/store/orders.store';
import { useAppSettingsStore } from '@/store/settings.store';
import { useTransactionStore } from '@/store/transaction.store';
import { mapActions, mapState, mapWritableState } from 'pinia';
import { useProfileStore } from './store/profile.store';
import { BLOCK_ONFOOD_SERVICE_ROUTES, adminRoutes, noAdminRoutes } from './router/routes';
import { SERVICE_NAME } from './lib/consts/serviceName.const';

const BLOCKED_NEXT_PAGE = new Set([
  routerNamesConst.ORDER,
  routerNamesConst.ORDERS,
  routerNamesConst.TRANSACTION,
  routerNamesConst,
]);

export default {
  name: 'App',
  components: { ToastItem },
  mixins: [TabletSizeMixin],
  data: () => ({
    isCookieEnabled: true,
  }),

  computed: {
    ...mapWritableState(useAppSettingsStore, ['locale', 'version']),
    ...mapState(useProfileStore, ['isAuthenticated', 'role', 'userPermission']),
    ...mapWritableState(useAppSettingsStore, ['isNavBlocked', 'isUserChangeNav']),

    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
  },
  methods: {
    ...mapActions(useProfileStore, { clearProfile: 'clear' }),
    ...mapActions(useTransactionStore, { clearTransaction: 'clear' }),
    ...mapActions(useOrdersStore, { clearOrders: 'clear' }),
    ...mapActions(useAppSettingsStore, ['setLocale', 'setUpdateVersion']),
    ...mapActions(useLoginFormStore, ['setLoading', 'setError']),
    ...mapActions(useProfileStore, ['getProfileData']),

    async checkVersionAndUpdate() {
      const currentUrl = window.location.origin;
      const url_check = `${currentUrl}/check-version`;
      const response = await fetch(url_check).then((res) => res.json());
      if (this.version !== response.version) {
        console.log(`CHECK VERSION. CURRENT: ${this.version}; VUE_APP_VERSION: ${process.env.VUE_APP_VERSION};`);
        this.setUpdateVersion(response.version);
        document.title = process.env.VUE_APP_TITLE;
        window.location.reload(true);
      }
    },

    checkAndCreateLocale() {
      if (!this.locale) {
        const availableLocales = this.$i18n.availableLocales;
        const usersLanguage = window.navigator.language;
        let locale = availableLocales.includes(usersLanguage) ? usersLanguage : 'en';
        this.setLocale(locale);
        this.$i18n.locale = locale;
      } else {
        this.$i18n.locale = this.locale;
      }
    },
    clear() {
      this.clearProfile();
      this.clearTransaction();
      this.clearOrders();
    },

    sendToast() {
      customEventHelper(
        eventBusConst.toast.show,
        {
          text:
            this.$t('cookie_disabled') +
            '.' +
            '  ' +
            this.$t('copy') +
            ' - <a href="chrome://settings/cookies" target="_blank">chrome://settings/cookies</a>',
          timeout: 100000,
        },
        true
      );
    },
    _adminRoutes(url) {
      return !adminRoutes.includes(url) ? routerNamesConst.DASHBOARD : url;
    },
    _operatorLogs(url) {
      if (this.userPermission === SERVICE_NAME.ONFOOD) {
        return !noAdminRoutes.includes(url)
          ? routerNamesConst.HISTORY
          : BLOCK_ONFOOD_SERVICE_ROUTES.includes(url)
          ? routerNamesConst.HISTORY
          : url;
      }
      return !noAdminRoutes.includes(url) ? routerNamesConst.SCAN : url;
    },
    generateNextStep(next) {
      if (!next) {
        return this.role === userRoleConst.operator ? routerNamesConst.SCAN : routerNamesConst.DASHBOARD;
      }
      if (BLOCKED_NEXT_PAGE.has(next)) {
        return this.role === userRoleConst.operator ? routerNamesConst.SCAN : routerNamesConst.DASHBOARD;
      }
      return this.role === userRoleConst.operator ? this._operatorLogs(next) : this._adminRoutes(next);
    },
    async logined(d) {
      logout()
        .then(() => this.clear())
        .finally(() => {
          login(d)
            .then(() =>
              this.getProfileData(d)
                .then(() => {
                  this.setError(false);
                  const nextStep = this.generateNextStep(this.$route.query?.next);
                  this.$router.push({ name: nextStep });
                })
                .catch(() => {
                  this.setError(true);
                  if (!navigator.cookieEnabled) {
                    this.sendToast();
                    return;
                  }
                  cookieCheck({
                    timeout: 1000,
                  }).then((res) => {
                    const { supported } = res;
                    if (!supported) this.sendToast();
                  });
                })
                .finally(() => this.setError(false))
            )
            .catch(() => {
              this.setError(true);
            })
            .finally(() => {
              this.setLoading(false);
            });
        });
    },
    async _sendLog() {
      const log = await db.getAll();
      if (log.length === 0) {
        return;
      }
      log.map(async (item) => {
        await db.delete(item.id);
      });
      logSend.setData();
      console.log(log);
    },
    async sendLogs() {
      const log_data_last_send = logSend.getData();
      if (!log_data_last_send) {
        await this._sendLog();
      } else {
        const _date = DateLib(log_data_last_send);
        const current_date = DateLib();
        const diff = _date.diff(current_date, 'day');
        if (diff > 1) {
          await this._sendLog();
        }
      }
    },
  },

  created() {
    this.checkAndCreateLocale();
    if (this.isTabletSize && !this.isUserChangeNav) this.isNavBlocked = false;
    document.title = process.env.VUE_APP_TITLE;

    this.checkVersionAndUpdate();

    addEventListener('focus', () => {
      this.checkVersionAndUpdate();
    });

    addEventListener(eventBusConst.redirect, (e) => {
      this.$router.replace(e.detail.path);
    });

    const broadcast = new BroadcastChannel(BROADCAST_CHANNEL_NAME.LOGOUT);
    broadcast.onmessage = () => {
      logout().finally(() => {
        this.$router.push({ name: routerNamesConst.LOGIN, query: { next: this.$route.name } });
        this.clear();
      });
    };

    const broadcast_login = new BroadcastChannel(BROADCAST_CHANNEL_NAME.LOGIN);
    broadcast_login.onmessage = (d) => {
      this.logined(d.data);
    };

    const transactionStore = localStorage.getItem('transactionStore');
    if (transactionStore) localStorage.removeItem('transactionStore');
    const ordersStore = localStorage.getItem('orders-store');
    if (ordersStore) localStorage.removeItem('orders-store');
  },
  beforeUpdate() {
    this.checkVersionAndUpdate();
  },
  beforeDestroy() {
    removeEventListener(eventBusConst.redirect, (e) => {
      this.$router.replace(e.detail.path);
    });
    const broadcast = new BroadcastChannel(BROADCAST_CHANNEL_NAME.LOGOUT);
    const broadcast_login = new BroadcastChannel(BROADCAST_CHANNEL_NAME.LOGIN);
    broadcast_login.close();
    broadcast.close();
  },
};
</script>

<style lang="scss">
@import url('@/assets/css/main.scss');
</style>
