export const TabletSizeMixin = {
  data: () => ({
    windowWidth: window.innerWidth,
  }),

  computed: {
    isTabletSize() {
      return this.windowWidth < 1280;
    },
  },

  methods: {
    catchResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  mounted() {
    window.addEventListener('resize', this.catchResize);
  },

  destroyed() {
    window.removeEventListener('resize', this.catchResize);
  },
};
