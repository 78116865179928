import http from '@/plugins/axios';

export const login = ({ user, pass, lng }) => {
  return http.post('/login', {
    login: user,
    password: pass,
    device_info: {
      locale: lng,
    },
  });
};

export const profile = () => http.get('/profile');

export const logout = () => http.post('/logout');

export const setLanguage = (lng) =>
  http.post('/language', {
    language_code: lng,
  });

export default {
  login,
  profile,
  logout,
  setLanguage,
};
