import db from '@/lib/db';
import DateLib from '@/lib/Date';
import { getProfileStore, getVersionApp } from '@/plugins/localStorage';

export const logger = {
  info: (object) => {
    createLog('info', object);
  },
  error: (object) => {
    createLog('error', object);
  },
};

const createLog = (status, object) => {
  const date = DateLib();
  const obj = {
    date: date.format('YYYY-MM-DD HH:mm:ss.SSS'),
    status: status,
    data: { ...object, user: getProfileStore()?.username },
    version_app: getVersionApp()?.version,
  };
  db.update(date.format('DD.MM.YYYY'), obj);
};
