import { defineStore } from 'pinia';

export const useLoginFormStore = defineStore('login-form-store', {
  persist: false,

  state: () => ({
    loading: false,
    error: false,
  }),

  actions: {
    setLoading(loading) {
      this.loading = loading;
    },
    setError(error) {
      this.error = error;
    },
  },
});
