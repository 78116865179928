import Vue from 'vue';
import VueRouter from 'vue-router';
import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import MainLayout from '@/layouts/main/MainLayout';

import { useProfileStore } from '@/store/profile.store';
import { userRoleConst } from '@/lib/consts/userRole.const';
import { routerNamesConst, routerPathConst } from '@/lib/consts/routerNames.const';
import { SERVICE_NAME } from '@/lib/consts/serviceName.const';
import { adminRoutes, BLOCK_ONFOOD_SERVICE_ROUTES, noAdminRoutes } from './routes';

Vue.use(PiniaVuePlugin);
export const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
Vue.use(VueRouter);

const routeGuards = (to, from, next) => {
  const store = useProfileStore();

  if (!store.isAuthenticated && to.name !== routerNamesConst.LOGIN) {
    return next({ name: routerNamesConst.LOGIN, query: { next: to.name } });
  }

  if (store.role === userRoleConst.admin && noAdminRoutes.includes(to.name)) {
    return next({ name: routerNamesConst.SCAN });
  }

  if (store.role === userRoleConst.operator && adminRoutes.includes(to.name)) {
    return next({ name: routerNamesConst.DASHBOARD });
  }

  if (
    store.role === userRoleConst.operator &&
    store.permission?.[0] === SERVICE_NAME.ONFOOD &&
    BLOCK_ONFOOD_SERVICE_ROUTES.includes(to.name)
  ) {
    return next({ name: routerNamesConst.HISTORY });
  }
  next();
};

const routes = [
  {
    path: '',
    component: () => import(/* webpackChunkName: "Login" */ '@/layouts/login/LoginLayout'),
    redirect: { name: routerNamesConst.LOGIN },
    children: [
      {
        path: routerPathConst.LOGIN,
        name: routerNamesConst.LOGIN,
        component: () => import(/* webpackChunkName: "Login" */ '@/views/login/LoginPage'),
      },
    ],
  },
  {
    path: '',
    component: MainLayout,
    children: [
      {
        path: routerPathConst.SCAN,
        name: routerNamesConst.SCAN,
        component: () => import(/* webpackChunkName: "Scan" */ '@/views/visit/ScanPage'),
      },
      {
        path: routerPathConst.ORDERS,
        name: routerNamesConst.ORDERS,
        component: () => import(/* webpackChunkName: "Scan" */ '@/views/visit/OrdersPage'),
      },
      {
        path: routerPathConst.ORDER,
        name: routerNamesConst.ORDER,
        component: () => import(/* webpackChunkName: "Scan" */ '@/views/visit/OrderPage'),
      },
      {
        path: routerPathConst.TRANSACTION,
        name: routerNamesConst.TRANSACTION,
        component: () => import(/* webpackChunkName: "Scan" */ '@/views/visit/TransactionPage'),
      },

      {
        path: routerPathConst.SETUP,
        name: routerNamesConst.SETUP,
        component: () => import(/* webpackChunkName: "Setup" */ '@/views/setup/SetupScannerPage'),
      },
      {
        path: routerPathConst.HISTORY,
        name: routerNamesConst.HISTORY,
        component: () => import(/* webpackChunkName: "History" */ '@/views/history/HistoryPage'),
      },
      {
        path: routerPathConst.STATISTICS,
        name: routerNamesConst.STATISTICS,
        component: () => import(/* webpackChunkName: "Statistics" */ '@/views/statistics/StatisticsPage'),
      },
      {
        path: routerPathConst.REPORTS,
        name: routerNamesConst.REPORTS,
        component: () => import(/* webpackChunkName: "Statistics" */ '@/views/reports/ReportsPage.vue'),
      },
      {
        path: routerPathConst.DASHBOARD,
        name: routerNamesConst.DASHBOARD,
        component: () => import(/* webpackChunkName: "Statistics" */ '@/views/dashboard/DashboardPage.vue'),
      },
      {
        path: '*',
        name: 'not found',
        component: () => import(/* webpackChunkName: "404" */ '../views/notFound/NotFoundPage'),
      },
    ],
  },
];

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(routeGuards);
