import { defineStore } from 'pinia';

export const setupString = '1234567890 the quick brown fox jumps over the lazy dog 0987654321';

export const useScannerStore = defineStore('scanner-store', {
  persist: {
    storage: localStorage,
  },

  state: () => ({
    prefix: null,
    postfix: null,
  }),

  getters: {
    isSetup: (state) => state.prefix !== null && state.postfix !== null,
  },

  actions: {
    setup(qr) {
      return new Promise((resolve, reject) => {
        try {
          [this.prefix, this.postfix] = qr.split(setupString).map((x) => x.trim());
        } catch (e) {
          console.log(e);
          reject();
        }

        this.prefix = this.prefix || '';
        this.postfix = this.postfix || '';
        resolve();
      });
    },

    removeScannerMeta(qr) {
      if (qr.startsWith(this.prefix)) qr = qr.slice(this.prefix.length);
      if (qr.endsWith(this.postfix)) qr = qr.slice(0, qr.length - this.postfix.length);
      return qr.trim();
    },

    clearScanner() {
      this.prefix = null;
      this.postfix = null;
    },
  },
});
