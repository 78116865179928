<template>
  <label class="label">
    <span :id="id" v-if="!$attrs.disableLabel">{{ $attrs.labelTo }}</span>
    <v-text-field
      :class="$attrs.className"
      outlined
      :id="id"
      background-color="inputBackground"
      single-line
      hide-details="auto"
      v-bind="$attrs"
      v-on="$listeners"
    >
    </v-text-field>
  </label>
</template>
<script>
export default {
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid.toString();
  },
};
</script>
<style lang="scss" scoped>
.label {
  display: block;
}
</style>
