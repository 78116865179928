import { openDB } from 'idb';
import DateLib from '@/lib/Date';

const DB_NAME = 'ONPASS';
const DB_STORE = 'logs';

const dbPromise = openDB(DB_NAME, 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains(DB_STORE)) {
      db.createObjectStore(DB_STORE, { keyPath: 'id' });
    }
  },
});

async function getDb() {
  const db = await dbPromise;
  const tx = db.transaction(DB_STORE, 'readwrite');
  const store = tx.objectStore(DB_STORE);
  return {
    db,
    tx,
    store,
  };
}

export default {
  async get(key) {
    const { store } = await getDb();
    return store.getKey(key);
  },
  async getAll() {
    const { store } = await getDb();
    return store.getAll();
  },
  async set(key, val) {
    const { tx, store } = await getDb();
    store.add({
      id: key,
      data: [val],
    });
    return tx.complete;
  },
  async delete(key) {
    const { tx } = await getDb();
    tx.objectStore(DB_STORE).delete(key);
    return tx.complete;
  },
  async update(key, newData) {
    const { store, tx } = await getDb();

    try {
      const existingData = await store.get(key);

      if (existingData) {
        const new_data = [...existingData.data, newData];
        const obj = {
          id: existingData.id,
          data: new_data,
        };
        store.put(obj);
      } else {
        await this.set(key, newData);
      }

      return tx.complete;
    } catch (e) {
      const date = DateLib();
      await this.update(date.format('DD.MM.YYYY'), e);
      return tx.complete;
    }
  },
};
