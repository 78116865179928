import { routerNamesConst } from '@/lib/consts/routerNames.const';

export const adminRoutes = [routerNamesConst.STATISTICS, routerNamesConst.REPORTS, routerNamesConst.DASHBOARD];
export const noAdminRoutes = [
  routerNamesConst.SCAN,
  routerNamesConst.ORDER,
  routerNamesConst.ORDERS,
  routerNamesConst.SETUP,
];
export const BLOCK_ONFOOD_SERVICE_ROUTES = [
  routerNamesConst.SCAN,
  routerNamesConst.ORDER,
  routerNamesConst.ORDERS,
  routerNamesConst.SETUP,
];
