module.exports = {
  ru: 'Русский',
  en: 'English',
  locale: 'en',

  back: 'Back',
  next: 'Next',
  close: 'Close',
  startAgainBtn: 'Start over',

  required: 'This field is required',
  cyrillicWarn: 'Check your keyboard layout',
  capsWarn: 'Check Caps Lock is off',

  navbar: {
    menu: 'Menu',
    operator: 'Lounge operator',
    admin: 'Lounge administrator',
    history: 'History',
    register: 'Registration',
    setup: 'Settings',
    guide: 'User guide',
    exit: 'Exit',
    statistics: 'Statistics',
    reports: 'Reports',
    dashboard: 'Main',
  },

  profileCard: {
    onpass: {
      registerVisits: 'Registration visits in',
      profileData: 'User profile data',
    },
    onfood: {
      registerVisits: 'Registration visits in',
      profileData: 'User profile data',
    },
    ontrack: {
      registerVisits: 'Registration visits in',
      profileData: 'User profile data',
    },
  },

  login: {
    authorization: 'Authorization',
    enterText: 'Enter your account data',
    loginLabel: 'Login',
    passwordLabel: 'Password',
    enter: 'Enter',
    wrongCreds: 'Wrong credential data',
  },

  setup: {
    qrTitle: 'To set up the scanner, scan the QR code...',
    qrScan: 'Scan the QR code to detect prefix and suffix automatically',
    inputLabel: 'Scanner data',
    scanBtn: 'Define and save',
    scannerSetupFailed: 'Scanner setup failed',
    setupSuccessTitle: 'Scanner Setup Successful',
    setupSuccessBody: 'Prefixes and suffixes define',
    goToScanBtn: 'Visit registration',
  },

  scan: {
    title: 'Scan the QR code',
    qrScanAnnotation: "Scan the customer's personal QR code or order QR code",
    qrInputLabel: 'Use the scanner to enter the QR code',
    qrNotFound: 'QR code was not found',
    scannerNotSetup: 'Scanner setup failed',
    thereIsNoPrefix: 'Prefixes and suffixes are not defined',
    setupScannerBtn: 'Set up scanner',
  },

  orders: {
    userNotFound: 'Сard Holder',
    available: 'Available orders',
    order: 'Order',
    orderDate: 'Ordered',
    availableUntil: 'Valid until',
    freeOrderTitle: 'Premium Bundle',
    empty: 'No orders available',
  },

  transaction: {
    orders: 'Will be registered',
    code_scans: 'Boarding pass scanning',
    confirmation_code: 'Entering by SMS code',
    compare_passenger_with_holder: 'Comparison of the landing',
    cmp_error_msg: {
      invalid: 'Automatic welding failed. Scan the boarding pass again.',
      boarding_scan_empty: 'The boarding ticket is empty. Automatic welding failed',
    },
    optional: 'Optional',
    skip: 'Skip',
    boarding: {
      title: 'Scan the boarding pass',
      label: 'Use the scanner to enter the boarding pass',
      wrongBoarding: 'Documents not confirmed',
      optionalError:
        'Automatic verification didn’t pass. Verify that the client’s name and surname match the boarding pass data and click «Skip»',
    },
    sms: {
      title: 'Enter the SMS code to confirm the write-off',
      sendAgainBtn: 'Send again',
      errors: {
        wrong: 'The code is not valid. Try again',
        tooManyWrong: 'The limit of incorrect confirmation code input is exceeded',
        tooManyRequests: 'The limit of confirmation code request is exceeded',
        requestError: 'SMS sending error',
      },
    },
    finish: {
      title: 'Complete registration',
      text: 'To complete the registration of the visits to the business lounge click «Complete»',
      orders: 'Order content',
      finishBtn: 'Complete',
    },
  },

  history: {
    error: 'Error. Reload the page',
    calendar: 'Pick date',
    allPoints: 'All entries',
    Transaction: 'Transaction date',
    Uuid: 'Uuid',
    Flight: 'Flight',
    Tariff: 'Tariff',
    Visits: 'Visits',
    services: 'Number of services',
    Passenger: 'Passenger',
    Operator: 'Operator',
    total: 'Total',
    visitHistory: {
      onpass: 'visit(s) during the period: ',
      onfood: 'visit(s) during the period: ',
      ontrack: 'visit(s) during the period: ',
    },
    searchBuilder: 'Set up a filter',
    timePicker: 'Input time',
    sum: 'Price',
  },

  search: 'Search',

  reports: {
    title: 'Reports by {year} year',
    generateBtn: 'Response format',
    table: {
      dateTime: 'Visit datetime',
      flight: 'Flight',
      flightDatetime: 'Flight datetime',
      passengerFullName: 'Passenger full name',
      operator: 'Operator',
      guest: 'Guest',
      visitPrice: 'Visit price',
      itemsPerPageText: 'Rows per page:',
      datePickerLocale: 'En-en',
    },
    select: {
      period: 'Period',
      lounge: {
        onpass: 'Lounge',
        onfood: 'Service point',
        ontrack: 'Service point',
      },
      label: {
        lounge: {
          onpass: 'Select lounge',
          onfood: 'Select a service point',
          ontrack: 'Select a service point',
        },
        period: 'input date',
      },
    },
    tableTitle: {
      ontrack: 'The total number of services rendered during the reporting period amounted to -',
      onfood: 'The total number of visits during the reporting period amounted to -',
      onpass: 'The total number of visits during the reporting period amounted to -',
    },
    selectYear: 'Select year',
    selectMonth: 'Select month',
    visits: 'Quantity of visits',
    price: 'Discount amount',
    services: 'Number of services',
  },

  month: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },

  all: 'All',
  select: {
    lounge: {
      onpass: 'Select lounge',
      onfood: 'Select a service point',
      ontrack: 'Select a service point',
    },
  },
  datePicker: {
    label: 'Input date',
    locale: 'En-en',
  },
  table: {
    noDate: 'No data available',
    locale: 'en-US',
  },

  weekDays: {
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday',
  },

  statistics: {
    byWeekDays: {
      onpass: 'Visits (by day of the week)',
      ontrack: 'Number of services (by day of the week)',
      onfood: 'Visits (by day of the week)',
    },
    byWeekColumn: 'Week day',
    byMonths: {
      onpass: 'Visits (by month)',
      onfood: 'Visits (by month)',
      ontrack: 'Number of services (by month)',
    },
    byMonthColumn: 'Month',
    byPoints: {
      ontrack: 'Number of services (by point of service delivery)',
      onfood: 'Visits (by point of service)',
      onpass: 'Visits (by lounge)',
    },
    byPointsColumn: 'Lounge',
    visits: 'Visits',
    total: 'Total',
  },

  clear: 'Clear',
  ok: 'Ok',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  loading: 'Loading...',
  cookie_disabled: 'Cookies are disabled. Select Block cookies from third-party sites in incognito mode',
  copy: 'Copy',
  device: {
    mobile: 'Mobile version',
    desktop: 'Full version',
  },
  'history.datepicker.to': 'Beginning of the period',
  'history.datepicker.from': 'End of the period',
};
