import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';

import App from '@/App.vue';
import vuetify from '@/plugins/vuetify';
import filters from '@/plugins/filters';
import { pinia, router } from './router';
import { i18n } from '@/plugins/i18n';
import '@/components/register';
import '@/assets/css/main.scss';

Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

Object.defineProperty(Vue.prototype, '$bus', {
  get: function () {
    return this.$root.bus;
  },
});

export default new Vue({
  i18n,
  vuetify,
  pinia,
  router,
  filters,
  data: {
    bus: new Vue({}),
  },
  render: (h) => h(App),
}).$mount('#app');
