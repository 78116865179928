import { defineStore } from 'pinia';

import api from '@/api/order.api';

export const BOARDING_STAGE = 'code_scans';
export const SMS_STAGE = 'confirmation_code';
export const COMPARE_BOARDING_STAGE = 'compare_passenger_with_holder';
export const FINISH_STAGE = 'finish';

export const STAGES_TO_SHOW = [SMS_STAGE, BOARDING_STAGE, COMPARE_BOARDING_STAGE];
export const STAGES = STAGES_TO_SHOW.concat([FINISH_STAGE]);

export const useTransactionStore = defineStore('transactionStore', {
  persist: false,

  state: () => ({
    qr: '',
    rates: [],
    requirements: [],
    scannedInfo: null,
    step: 1,
    boarding: null,
    firstCheckCMP: true,
    firstCheckSMS: true,
    transaction_id: null,
  }),

  getters: {
    requirementsToShow: (state) => state.requirements.filter((i) => STAGES_TO_SHOW.includes(i.tag)),
    formatedRates: (state) =>
      state.rates.map((i) => ({
        product_id: i.product_id,
        count: i.count,
      })),
    stage: (state) => state.requirementsToShow[state.step - 1],
  },

  actions: {
    clear() {
      this.qr = '';
      this.rates = [];
      this.requirements = [];
      this.setScannedInfo(null);
      this.step = 1;
      this.boarding = null;
      this.firstCheckCMP = true;
      this.firstCheckSMS = true;
      this.transaction_id = null;
    },
    setBoarding(boarding) {
      this.boarding = boarding;
    },

    setFirstCheckCMP() {
      this.firstCheckCMP = false;
    },
    setFirstCheckSMS() {
      this.firstCheckSMS = false;
    },

    initRates(order) {
      this.rates = order.products
        .filter((i) => i.remainder > 0)
        .map((i, idx) => ({
          ...i,
          count: idx === 0 ? 1 : 0,
          minCount: 0
        }));
    },

    setRequirements(order) {
      if (this.qr === order.qr) {
        this.requirements.forEach((i) => {
          i.name = order.requirements.find((el) => el.tag === i.tag)?.name || i.name;
        });
      } else {
        this.requirements = order.requirements
          .filter((i) => STAGES.includes(i.tag))
          .map((i) => ({
            name: i.name,
            tag: i.tag,
            optional: i.optional,
            error: false,
          }))
          .concat({ tag: FINISH_STAGE });
      }
    },
    setTransactionId(id) {
      this.transaction_id = id;
    },
    setScannedInfo(info) {
      this.scannedInfo = info;
    },

    start({ order, signal }) {
      this.qr = '';

      return api
        .startOrder({
          qr: order.qr,
          products: this.formatedRates,
          signal,
        })
        .then((r) => {
          if (!r) {
            throw new Error();
          }
          this.setRequirements(order);
          this.qr = order.qr;
          this.setTransactionId(r?.data?.data?.transaction_id ?? null);
          return r?.data;
        });
    },

    finish({ signal }) {
      return api.closeOrder({
        qr: this.qr,
        products: this.formatedRates,
        transaction_id: this.transaction_id,
        signal,
      });
    },

    setError(tag, error) {
      const stage = this.requirements.findIndex((i) => i.tag === tag);
      if (stage !== -1) this.requirements[stage].error = error;
    },

    stageAfter(tag) {
      const index = this.requirements.findIndex((i) => i.tag === tag);
      if (index !== -1 && index !== this.requirements.length - 1) return this.requirements[index + 1];
      else return { tag: 'finish' };
    },
  },
});
