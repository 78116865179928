<template>
  <v-list nav dense :class="mini ? '' : 'px-2'">
    <v-list-item class="my-2">
      <v-list-item-title v-show="!mini">{{ $t('navbar.menu') }}</v-list-item-title>
    </v-list-item>

    <v-list-item v-for="page in dashboardPage" :key="page.title" v-bind="page" v-on="page">
      <v-list-item-icon>
        <v-icon color="#a9b7d0">{{ page.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-title style="color: #a9b7d0">
        {{ page.title }}
      </v-list-item-title>
    </v-list-item>

    <v-list-item class="my-2">
      <v-list-item-title v-show="!mini">
        {{ pagesTitle }}
      </v-list-item-title>
    </v-list-item>

    <v-list-item v-for="page in pages" :key="page.title" v-bind="page" v-on="page">
      <v-list-item-icon>
        <v-icon color="#a9b7d0">{{ page.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-title style="color: #a9b7d0">
        {{ page.title }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapState } from 'pinia';
import { useProfileStore } from '@/store/profile.store';
import { userRoleConst } from '@/lib/consts/userRole.const';
import { routerNamesConst } from '@/lib/consts/routerNames.const';
import { BROADCAST_CHANNEL_NAME } from '@/lib/consts/BroadcastChannelConst';
import { useAppSettingsStore } from '@/store/settings.store';
import { SERVICE_NAME } from '@/lib/consts/serviceName.const';

export default {
  props: ['mini'],
  computed: {
    ...mapState(useProfileStore, ['role', 'userPermission']),
    ...mapState(useAppSettingsStore, ['isDesktop']),

    pages() {
      const OPERATOR_PAGE = {
        default: [
          {
            icon: 'mdi-account-multiple',
            title: this.$t('navbar.guide'),
            href: `/media/user_guide_${this.$i18n.locale}.pdf`,
            target: '_blank',
          },
        ],
        [SERVICE_NAME.ONPASS]: [
          {
            icon: 'mdi-qrcode-scan',
            title: this.$t('navbar.register'),
            to: { name: routerNamesConst.SCAN },
          },
          {
            icon: 'mdi-account-multiple',
            title: this.$t('navbar.guide'),
            href: `/media/user_guide_${this.$i18n.locale}.pdf`,
            target: '_blank',
          },
        ],
      };

      let pages = [
        {
          icon: 'mdi-television',
          title: this.$t('navbar.history'),
          to: { name: routerNamesConst.HISTORY },
        },
      ];
      const operatorPages = OPERATOR_PAGE[this.userPermission] || OPERATOR_PAGE.default;

      switch (this.role) {
        case userRoleConst.operator:
          pages = !this.isDesktop
            ? [...pages, ...operatorPages]
            : [
                ...pages,
                ...operatorPages,
                this.userPermission === SERVICE_NAME.ONFOOD
                  ? undefined
                  : {
                      icon: 'mdi-cog-outline',
                      title: this.$t('navbar.setup'),
                      to: { name: routerNamesConst.SETUP },
                    },
              ];
          break;

        case userRoleConst.admin:
          pages = [
            ...pages,
            {
              icon: 'mdi-chart-donut',
              title: this.$t('navbar.statistics'),
              to: { name: routerNamesConst.STATISTICS },
            },
            {
              icon: 'mdi-file-document',
              title: this.$t('navbar.reports'),
              to: { name: routerNamesConst.REPORTS },
            },
          ];
          break;
      }

      pages = pages.concat([
        {
          icon: 'mdi-power',
          title: this.$t('navbar.exit'),
          click: () => {
            const broadcast = new BroadcastChannel(BROADCAST_CHANNEL_NAME.LOGOUT);
            broadcast.postMessage('logout');
          },
        },
      ]);
      return pages.filter((i) => i !== undefined);
    },

    pagesTitle() {
      switch (this.role) {
        case userRoleConst.operator:
          return this.$t('navbar.operator');
        case userRoleConst.admin:
          return this.$t('navbar.admin');
        default:
          return '';
      }
    },

    dashboardPage() {
      switch (this.role) {
        case userRoleConst.admin:
          return [
            {
              icon: 'mdi-home',
              title: this.$t('navbar.dashboard'),
              to: { name: routerNamesConst.DASHBOARD },
            },
          ];
        default:
          return [];
      }
    },
  },
};
</script>
