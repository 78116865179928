<template>
  <v-snackbar v-model="snackbar" :timeout="timeout" light right top>
    <span v-html="text"></span>

    <template v-slot:action="{ attrs }">
      <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
        {{ $t('close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { eventBusConst } from '@/lib/consts/eventBus.const';

export default {
  name: 'Toast-Item',
  data: () => ({
    snackbar: false,
    text: '',
    timeout: 2000,
  }),
  methods: {
    show(text, timeout = 2000) {
      this.text = text;
      this.timeout = timeout;
      this.snackbar = true;
    },
  },
  mounted() {
    addEventListener(eventBusConst.toast.show, (e) => {
      this.show(e.detail.text, e.detail.timeout);
    });
  },
  beforeDestroy() {
    removeEventListener(eventBusConst.toast.show, (e) => {
      this.show(e.detail.text, e.detail.timeout);
    });
  },
};
</script>

<style lang="scss" scoped></style>
