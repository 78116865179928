<template>
  <v-switch @change="onChange" class="box__switch" v-model="isDesktop" :label="msg"></v-switch>
</template>

<script>
import { TouchDeviceMixin } from '@/lib/mixins/TouchDeviceMixin';
import { mapActions, mapState } from 'pinia';
import { useAppSettingsStore } from '@/store/settings.store';
import { useScannerStore } from '@/store/scanner.store';

export default {
  props: ['mini'],
  mixins: [TouchDeviceMixin],
  data() {
    return {
      toggle: false,
    };
  },
  methods: {
    ...mapActions(useAppSettingsStore, ['setDesktopToggle']),
    ...mapActions(useScannerStore, ['setup', 'clearScanner']),
    onChange(v) {
      this.setDesktopToggle(v);
      if (v === false) {
        this.setup('mobile_phone 1234567890 the quick brown fox jumps over the lazy dog 0987654321 install');
        if (this.$route.path === '/setup') {
          this.$router.push('/scan');
        }
      }
      if (v === true) {
        this.clearScanner();
      }
    },
  },
  computed: {
    ...mapState(useAppSettingsStore, ['isDesktop']),
    msg() {
      if (this.mini) return '';
      return this.isDesktop ? this.$t('device.desktop') : this.$t('device.mobile');
    },
  },
  created() {
    if (this.isDesktop === undefined) {
      this.setDesktopToggle(!this.onTouchDevice);
    }
  },
};
</script>

<style lang="scss">
.box__switch {
  .v-input__slot {
    justify-content: center !important;

    label {
      flex: none !important;
    }
  }
}
</style>
