import { defineStore } from 'pinia';

import { checkQr } from '@/api/order.api';

import { useScannerStore } from './scanner.store';
import { useTransactionStore } from './transaction.store';

export const useOrdersStore = defineStore('orders-store', {
  persist: false,

  state: () => ({
    qr: '',
    orders: [],
    selectedId: null,
    loading: false,
  }),

  getters: {
    selectedOrder: (state) => state.orders.find((i) => i.id === state.selectedId),
    ordersCount: (state) => state.orders.length,
  },

  actions: {
    clear() {
      this.orders = [];
      this.selectedId = null;
      this.qr = '';
    },

    setQr(qr) {
      this.qr = qr;
    },

    selectOrderById(id) {
      this.selectedId = id;
      const transaction = useTransactionStore();
      transaction.initRates(this.selectedOrder);
    },

    scan(qr) {
      const scannerStore = useScannerStore();
      qr = scannerStore.removeScannerMeta(qr);

      if (qr !== this.qr) this.clear();

      this.loading = true;
      return checkQr(qr)
        .then((r) => {
          this.setQr(qr);
          let orders = r.data?.data?.orders;
          if (!orders) {
            if (r.data?.data?.order) orders = [r.data?.data?.order];
            else orders = [];
          }
          this.orders = orders;
          this.selectOrderById(orders[0]?.id);
        })
        .finally((v) => {
          this.loading = false;
          return v;
        });
    },
  },
});
