export const TouchDeviceMixin = {
  computed: {
    onTouchDevice() {
      const platform = navigator.platform.toLowerCase();
      return (
        ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) &&
        'mediaDevices' in navigator &&
        'getUserMedia' in navigator.mediaDevices &&
        !platform.includes('win')
      );
    },
  },
};
