<template>
  <v-select
    v-model="model"
    :items="pointsToSelector"
    item-text="state"
    item-value="id"
    outlined
    :label="$t(`select.lounge.${userPermission}`)"
    v-bind="$attrs"
    v-on="$listeners"
    background-color="inputBackground"
    hide-details
    :disabled="disabled"
  >
  </v-select>
</template>

<script>
import { useProfileStore } from '@/store/profile.store';
import { mapState } from 'pinia';
export default {
  props: ['value', 'disabled'],

  computed: {
    ...mapState(useProfileStore, ['points', 'userPermission']),

    pointsToSelector() {
      return [{ state: this.$t('all'), id: null }].concat(
        this.points.map((item) => ({ state: `${item.airport}${item.pointName}`, id: item.point_id }))
      );
    },

    model: {
      get() {
        return this.value;
      },

      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>
