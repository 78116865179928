<template>
  <v-select
    v-model="year"
    :items="years"
    :label="$t('reports.selectYear')"
    outlined
    hide-details
    dense
    background-color="inputBackground"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: new Date().getFullYear(),
    },
    currentYear: {
      type: Number,
      default: new Date().getFullYear(),
    },
    selector: Boolean,
  },

  computed: {
    year: {
      get() {
        return this.value;
      },

      set(v) {
        this.$emit('input', v);
      },
    },

    years() {
      const currentYear = Number(this.currentYear);
      return Array.from(Array(4).keys())
        .map((i) => currentYear - i)
        .reverse();
    },
  },
};
</script>
