import DateLib from '../Date';

export const dateToQueryHelperNew = (date) => {
  return DateLib(date).format('YYYY-MM-DD HH:mm:ss');
};

export const generateLastMinutesFromDay = (date) => {
  const _date = new Date(date);
  if (!_date) return null;
  _date.setDate(_date.getDate() + 1);
  return new Date(_date - 1000);
};
