export const routerNamesConst = {
  LOGIN: 'login',
  SCAN: 'scan',
  ORDERS: 'orders',
  ORDER: 'order',
  TRANSACTION: 'transaction',
  SETUP: 'setup',
  HISTORY: 'history',
  STATISTICS: 'statistics',
  REPORTS: 'reports',
  DASHBOARD: 'dashboard',
};

export const routerPathConst = {
  LOGIN: '/login',
  SCAN: '/scan',
  ORDERS: '/orders',
  ORDER: '/order',
  TRANSACTION: '/transaction',
  SETUP: '/setup',
  HISTORY: '/history',
  STATISTICS: '/statistics',
  REPORTS: '/reports',
  DASHBOARD: '/dashboard',
};
