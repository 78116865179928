export const eventBusConst = {
  toast: {
    show: 'toast-show',
  },
  btn: {
    row: {
      next: {
        focus: 'btnRowNextBtnFocus',
      },
      back: {
        focus: 'btnRowBackBtnFocus',
      },
      again: {
        focus: 'btnRowStartAgainBtnFocus',
      },
    },
    scan: {
      focus: 'nextQrScanBtn',
      click: 'nextQrScanBtnClick',
    },
  },
  redirect: 'redirect',
};
