<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        fab
        v-bind="{ ...$attrs, ...attrs }"
        v-on="{ ...$listeners, ...on }"
        small
        elevation="0"
        :disabled="disabled"
        :style="disabled ? 'background-color: #9e9e9e!important;' : ''"
      >
        <v-icon> mdi-download</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item v-for="(item, i) in items" :key="i" @click="download(item.value)">
        <v-list-item-content>
          <v-list-item-title class="title text-center">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    items: Array,
    download: Function,
    disabled: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  color: #9e9e9e !important;
}
</style>
