module.exports = {
  ru: 'Русский',
  en: 'English',
  locale: 'ru',

  back: 'Назад',
  next: 'Далее',
  close: 'Закрыть',
  startAgainBtn: 'Начать заново',

  required: 'Поле необходимо заполнить',
  cyrillicWarn: 'Проверьте раскладку клавиатуры',
  capsWarn: 'Проверьте, выключен ли Caps Lock',

  navbar: {
    menu: 'Меню',
    operator: 'Оператор зала',
    admin: 'Администратор зала',
    history: 'История посещений',
    register: 'Регистрация',
    setup: 'Настройки',
    guide: 'Инструкция пользователя',
    exit: 'Выход из профиля',
    statistics: 'Статистика',
    reports: 'Отчеты',
    dashboard: 'Главная',
  },

  profileCard: {
    onpass: {
      registerVisits: 'Регистрация визитов в',
      profileData: 'Учетный данные пользователя',
    },
    onfood: {
      registerVisits: 'Регистрация посещений в',
      profileData: 'Учетный данные пользователя',
    },
    ontrack: {
      registerVisits: 'Регистрация посещений в',
      profileData: 'Учетный данные пользователя',
    },
  },

  login: {
    authorization: 'Авторизация',
    enterText: 'Введите свои учетные данные',
    loginLabel: 'Имя пользователя',
    passwordLabel: 'Пароль',
    enter: 'Войти',
    wrongCreds: 'Неверные учетные данные',
  },

  setup: {
    qrTitle: 'Для настройки сканера выполните сканирование QR-кода...',
    qrScan: 'Отсканируйте QR-код для автоматического определения префикса и суффикса',
    inputLabel: 'Данные со сканера',
    scanBtn: 'Определить и сохранить',
    scannerSetupFailed: 'Ошибка настройки сканера',
    setupSuccessTitle: 'Настройка сканера выполнена успешно',
    setupSuccessBody: 'Префиксы и суффиксы определены',
    goToScanBtn: 'Регистрация визита',
  },

  scan: {
    title: 'Сканирование QR-кода',
    qrScanAnnotation: 'Отсканируйте QR-код',
    qrInputLabel: 'Для ввода QR-кода воспользуйтесь сканером',
    qrNotFound: 'QR-код не найден',
    scannerNotSetup: 'Настройка сканера не проведена',
    thereIsNoPrefix: 'Префиксы и cуффиксы не определены',
    setupScannerBtn: 'Настроить сканер',
  },

  orders: {
    userNotFound: 'Владелец карты',
    available: 'Доступно заказов',
    order: 'Заказ',
    orderDate: 'Оформлен',
    availableUntil: 'Действителен до',
    freeOrderTitle: 'Премиальный пакет',
    empty: 'Нет доступных заказов',
  },

  transaction: {
    orders: 'Будет зарегистрировано',
    code_scans: 'Сканирование посадочного талона',
    confirmation_code: 'Вход по SMS коду',
    compare_passenger_with_holder: 'Сравнение посадочного',
    cmp_error_msg: {
      invalid: 'Автоматическая сверка не пройдена. Отсканируйте посадочный снова.',
      boarding_scan_empty: 'Посадочный билет пустой. Автоматическая сверка не пройдена',
    },
    optional: 'Опционально',
    skip: 'Пропустить',
    boarding: {
      title: 'Для подтверждения списания отсканируйте посадочный талон',
      label: 'Для ввода штрих-кода посадочного талона воспользуйтесь сканером',
      wrongBoarding: 'Документы не подтверждены',
      optionalError:
        'Автоматическая сверка не пройдена. Убедитесь, что имя и фамилия клиента совпадают с данными посадочного талона и нажмите кнопку «Пропустить»',
    },
    sms: {
      title: 'Для подтверждения списания введите SMS код',
      sendAgainBtn: 'Отправить повторно',
      errors: {
        wrong: 'Введен неверный код подтверждения. Повторите попытку',
        tooManyWrong: 'Превышен лимит запросов кода подтверждения',
        tooManyRequests: 'Превышен лимит ввода некорректного кода подтверждения',
        requestError: 'Ошибка отправки SMS',
      },
    },
    finish: {
      title: 'Завершите списание',
      text: 'Для завершения списания проходов в бизнес-зал нажмите «Завершить»',
      orders: 'Состав заказа',
      finishBtn: 'Завершить',
    },
  },

  history: {
    error: 'Возникла ошибка. Перезагрузите страницу',
    calendar: 'Выберите дату',
    allPoints: 'Все',
    Transaction: 'Дата транзакции',
    Uuid: 'Uuid',
    Flight: 'Полет',
    Tariff: 'Тариф',
    Visits: 'Посещения',
    services: 'Кол-во услуг',
    Passenger: 'Пассажир',
    Operator: 'Оператор',
    total: 'Всего',
    visitHistory: {
      onpass: 'визита(ов) за указанный период: ',
      onfood: 'посещений за указанный период: ',
      ontrack: 'посещений за указанный период: ',
    },
    searchBuilder: 'Настроить фильтр',
    timePicker: 'Время',
    sum: 'Сумма скидки',
  },
  search: 'Поиск',

  reports: {
    title: 'Отчеты за {year} год',
    generateBtn: 'ФОРМИРОВАНИЕ ОТЧЕТА',
    table: {
      dateTime: 'Дата и время',
      flight: 'Рейс',
      flightDatetime: 'Дата и время вылета',
      passengerFullName: 'ФИО',
      operator: 'Оператор',
      guest: 'Гость',
      visitPrice: 'Стоимость',
      itemsPerPageText: 'Строк на странице:',
    },
    select: {
      period: 'Период',
      lounge: {
        onpass: 'ЗПК',
        onfood: 'Точка оказания услуги',
        ontrack: 'Точка оказания услуги',
      },
      label: {
        lounge: {
          onpass: 'Выберите ЗПК',
          onfood: 'Выберите точку оказания услуги',
          ontrack: 'Выберите точку оказания услуги',
        },
        period: 'Введите дату',
      },
    },
    tableTitle: {
      ontrack: 'Общее количество оказанных услуг за отчетный период составило - ',
      onfood: 'Общее количество посещений за отчетный период составило - ',
      onpass: 'Общее количество визитов за отчетный период составило - ',
    },
    selectYear: 'Выберите год',
    selectMonth: 'Выберите месяц',
    visits: 'Кол-во визитов',
    price: 'Сумма скидки',
    services: 'Кол-во услуг',
  },

  month: {
    january: 'Январь',
    february: 'Февраль',
    march: 'Март',
    april: 'Апрель',
    may: 'Май',
    june: 'Июнь',
    july: 'Июль',
    august: 'Август',
    september: 'Сентябрь',
    october: 'Октябрь',
    november: 'Ноябрь',
    december: 'Декабрь',
  },

  all: 'Все',
  select: {
    lounge: {
      onpass: 'Выберите ЗПК',
      onfood: 'Выберите точку оказания услуги',
      ontrack: 'Выберите точку оказания услуги',
    },
  },
  datePicker: {
    label: 'Введите дату',
    locale: 'Ru-ru',
  },
  table: {
    noDate: 'Нет доступных данных',
    locale: 'ru-RU',
  },

  weekDays: {
    Monday: 'Понедельник',
    Tuesday: 'Вторник',
    Wednesday: 'Среда',
    Thursday: 'Четверг',
    Friday: 'Пятница',
    Saturday: 'Суббота',
    Sunday: 'Воскресенье',
  },

  statistics: {
    byWeekDays: {
      onpass: 'Визиты (по дням недели)',
      ontrack: 'Количество услуг (по дням недели)',
      onfood: 'Посещения (по дням недели)',
    },
    byWeekColumn: 'День недели',
    byMonths: {
      onpass: 'Визиты (по месяцам)',
      onfood: 'Посещения (по месяцам)',
      ontrack: 'Количество услуг (по месяцам)',
    },
    byMonthColumn: 'Месяц',
    byPoints: {
      ontrack: 'Количество услуг (по точке оказания услуг)',
      onfood: 'Посещения (по точкам оказания услуг)',
      onpass: 'Визиты (по ЗПК)',
    },
    byPointsColumn: 'ЗПК',
    visits: 'Количество визитов',
    total: 'Всего',
  },
  clear: 'Очистить',
  ok: 'Выбрать',
  cancel: 'Закрыть',
  yes: 'Да',
  no: 'Нет',
  loading: 'Загрузка...',
  cookie_disabled: 'Файлы Cookies отключены. Выберите Блокировать файлы cookie сторонних сайтов в режиме инкогнито',
  copy: 'Скопировать',
  device: {
    mobile: 'Мобильная версия',
    desktop: 'Полная версия',
  },
  'history.datepicker.to': 'Начало периода',
  'history.datepicker.from': 'Конец периода',
};
