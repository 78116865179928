import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  options: {
    customProperties: true,
  },

  theme: {
    themes: {
      light: {
        primary: '#04a9f5',
        bars: '#1b1e32',
        inputBackground: '#f5f7fa',
        inputWrapper: '#cce5ff',
        link: '#51A7EF',
        background: '#f4f7fa',
      },
    },
  },
  icons: {
    defaultSet: 'mdi',
  },
});
