<template>
  <div @wheel.prevent="useWheel($event.deltaY)">
    <v-icon tabindex="-1" class="no-select" @click="minus"> mdi-chevron-left </v-icon>

    <v-avatar class="input-border" size="30" tabindex="0" ref="inputElement">
      <span class="black--text subtitle-1 font-weight-bold">{{ v }}</span>
    </v-avatar>

    <v-icon tabindex="-1" class="no-select" @click="plus"> mdi-chevron-right </v-icon>

    <keyboard-events @keydown="keyboardEvent" />
  </div>
</template>

<script>
import { eventBusConst } from '@/lib/consts/eventBus.const';

export default {
  props: {
    min: {
      default: 0,
    },
    max: {
      default: null,
    },
    value: {
      default: 0,
    },
    loading: {
      default: false,
    },
  },

  data: () => ({
    methodUsing: null,
  }),

  created() {
    if (this.v < this.min) this.v = this.min;
  },

  methods: {
    minus() {
      if (this.loading) return;
      if (this.v > this.min) this.v -= 1;
    },

    plus() {
      if (this.loading) return;
      if (!this.max || this.v < this.max) this.v += 1;
    },

    keyboardEvent(e) {
      if (e.which === 13) this.$bus.$emit(eventBusConst.btn.row.next.focus);
      else if (e.which === 27) this.$bus.$emit(eventBusConst.btn.row.back.focus);
      else if (document.activeElement === this.$refs.inputElement.$el) {
        if (e.which === 40 || e.which === 37) {
          this.minus();
        } else if (e.which === 38 || e.which === 39) {
          this.plus();
        }
      }
    },

    useWheel(v) {
      if (v > 0) {
        this.minus();
      } else {
        this.plus();
      }
    },
  },

  computed: {
    v: {
      get() {
        return this.value;
      },

      set(v) {
        this.$emit('input', v);
        this.$nextTick(() => this.$forceUpdate());
      },
    },
  },
};
</script>

<style scoped lang="css">
.input-border {
  border: solid #888888 2px;
}

.no-select {
  width: 30px;
}

.no-select::after {
  background-color: transparent !important;
}

.no-select:hover {
  color: rgba(0, 0, 0, 0.8);
  font-size: 30px;
}
</style>
