import { useOrdersStore } from '@/store/orders.store';
import { useTransactionStore } from '@/store/transaction.store';
import { customEventHelper } from '@/lib/helpers/customEvent.helper';
import { eventBusConst } from '@/lib/consts/eventBus.const';
import { errorMsg } from '@/lib/consts/fatalError.const';
import { useAppSettingsStore } from '@/store/settings.store';

export const resetTransactionHelper = (responseCode) => {
  const settingsStore = useAppSettingsStore();
  const msg = `${responseCode}: ${errorMsg('default', settingsStore.locale.toLowerCase())}`;
  useOrdersStore().clear();
  useTransactionStore().clear();
  customEventHelper(eventBusConst.toast.show, { text: msg });
  customEventHelper(eventBusConst.redirect, { path: '/scan' });
};
