<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <base-input
        :value="dateToShow"
        :label="$t('datePicker.label')"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="{ ...attrs, ...(inputAttrs ?? {}) }"
        v-on="{ ...on, ...(inputListeners ?? {}) }"
        :disabled="disabled"
      ></base-input>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
      :picker-date.sync="pickerDate"
      :locale="$t('datePicker.locale')"
      :first-day-of-week="firstDayOfWeek"
      v-bind="pickerAttrs || {}"
      v-on="pickerListeners || {}"
      show-current
    >
      <v-row justify="space-around" class="py-1">
        <v-btn text color="primary" @click="clear">
          {{ $t('clear') }}
        </v-btn>
        <v-btn text color="primary" @click="close">
          {{ $t('cancel') }}
        </v-btn>
      </v-row>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { dateFilter, dateParse } from '@/plugins/filters';

export default {
  props: ['value', 'inputAttrs', 'inputListeners', 'pickerAttrs', 'pickerListeners', 'disabled'], // Date or null

  data: () => ({
    menu: false,
    pickerDate: null,
  }),

  computed: {
    firstDayOfWeek() {
      return 1;
    },

    dateToShow() {
      return dateFilter(this.date);
    },

    date: {
      get() {
        return this.value === null ? null : dateParse(this.value).format('YYYY-MM-DD');
      },

      set(v) {
        if (v !== null) this.$refs.menu.save(v);
        this.$emit('input', v === null ? null : dateParse(v));
      },
    },
  },

  methods: {
    close() {
      this.menu = null;
    },

    clear() {
      this.date = null;
      this.pickerDate = this.pickerAttrs?.showCurrent || dateParse().format('YYYY-MM-DD');
    },
  },
};
</script>

<style lang="scss">
.input-bold {
  input {
    font-weight: 900;
  }
}
</style>
