<template>
  <v-row class="row-picker">
    <v-col cols="12" sm="12" md="6">
      <v-row class="row-picker" style="gap: 5px" no-gutters>
        <v-col cols="12" sm="12" :md="showTime ? 7 : 12">
          <simple-date-picker
            :disabled="disabled"
            v-model="dates[0]"
            :picker-attrs="{
              max: maxFrom,
              ...(pickerAttrs || {}),
            }"
            :input-attrs="{ ...(inputAttrs || {}), ...(leftInputAttrs || {}) }"
          />
        </v-col>
        <v-col v-if="showTime" sm="12" md="4">
          <simple-time-picker
            :input-attrs="{ ...leftInputAttrs, disableLabel: true }"
            :disabled="onDisabled(dates[0])"
            v-model="times[0]"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="12" :md="6">
      <v-row class="row-picker" style="gap: 5px" no-gutters>
        <v-col cols="12" sm="12" :md="showTime ? 7 : 12">
          <simple-date-picker
            :disabled="disabled"
            v-model="dates[1]"
            :picker-attrs="{ min: maxTo, ...(pickerAttrs || {}) }"
            :input-attrs="{ ...(inputAttrs || {}), ...(rightInputAttrs || {}) }"
          />
        </v-col>
        <v-col v-if="showTime" sm="12" md="4">
          <simple-time-picker
            :input-attrs="{ ...rightInputAttrs, disableLabel: true }"
            :disabled="onDisabled(dates[1])"
            v-model="times[1]"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import SimpleTimePicker from '@/components/ui/time/SimpleTimePicker.vue';
import { dateParse } from '@/plugins/filters';
import { dateToQueryHelperNew } from '@/lib/helpers/dateToQuery.helper';

export default {
  components: { SimpleTimePicker },
  props: [
    'value',
    'inputAttrs',
    'leftInputAttrs',
    'rightInputAttrs',
    'pickerAttrs',
    'showTime',
    'timeValue',
    'disabled',
  ],

  data: () => ({
    dates: [null, null],
    times: ['', ''],
  }),

  computed: {
    maxTo() {
      return this.convertMaxMin(this.dates[0]);
    },

    maxFrom() {
      return this.convertMaxMin(this.dates[1]);
    },
  },

  created() {
    this.dates = [
      this.value[0] === null ? null : dateParse(this.value[0]),
      this.value[1] === null ? null : dateParse(this.value[1]),
    ];

    if (this.timeValue) {
      this.times = [
        this.timeValue[0] === '' ? '' : this.timeValue[0],
        this.timeValue[1] === '' ? '' : this.timeValue[1],
      ];
    }
  },

  methods: {
    onDisabled(date) {
      if (this.disabled) {
        return true;
      }
      return date === null;
    },
    convertMaxMin(data) {
      return data ? dateParse(dateToQueryHelperNew(data).split(' ')[0]).format('').split('+')[0] : undefined;
    },
  },

  watch: {
    value(v) {
      this.dates = v.length === 2 ? v : [null, null];
    },
    timeValue(v) {
      this.times = v.length === 2 ? v : ['', ''];
    },
    dates(v) {
      this.$emit('input', v);
    },
    times(v) {
      this.$emit('timeupdate', v);
    },
  },
};
</script>
<style scoped lang="scss">
.row-picker {
  align-items: end;
}
</style>
