import Vue from 'vue';

import BtnsRow from './BtnsRow.vue';
import NumberCircleInput from './ui/NumberCircleInput';
import BaseInput from './ui/BaseInput';

import PageCard from './ui/PageCard.vue';

import SimpleDatePicker from './ui/time/SimpleDatePicker';
import DatePickerPair from './ui/time/DatePickerPair';

import PointSelect from './ui/selects/PointSelect';
import YearSelect from './ui/selects/YearSelect';

import PointDatesInputs from './ui/PointDatesInputs';

import ChartBarBase from './ui/charts/ChartBarBase';

import DownloadFab from './ui/buttons/DownloadFAB';
import KeyboardEvents from '@/components/KeyboardEvents.vue';

Vue.component('BtnsRow', BtnsRow);
Vue.component('NumberCircleInput', NumberCircleInput);
Vue.component('BaseInput', BaseInput);
Vue.component('PageCard', PageCard);
Vue.component('SimpleDatePicker', SimpleDatePicker);
Vue.component('DatePickerPair', DatePickerPair);
Vue.component('PointSelect', PointSelect);
Vue.component('PointDatesInputs', PointDatesInputs);
Vue.component('YearSelect', YearSelect);
Vue.component('ChartBarBase', ChartBarBase);
Vue.component('DownloadFab', DownloadFab);
Vue.component('KeyboardEvents', KeyboardEvents);

export default Vue;
