<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-if="onTouchDevice" ref="input" v-on="on" :class="mClass">{{ time || $t('history.timePicker') }}</div>
      <base-input
        v-else
        autocomplete="off"
        v-model="time"
        v-maska
        data-maska="##:##"
        dense
        ref="input"
        :label="$t('history.timePicker')"
        v-bind="{ ...attrs, ...(inputAttrs || {}) }"
        v-on="{ ...on, ...(inputListeners || {}) }"
        @input="onInputTime"
        :disabled="disabled"
      ></base-input>
    </template>
    <v-card class="timepicker-box">
      <v-row justify="center" class="timepicker-container">
        <v-col class="timepicker-item" cols="6" @wheel.prevent="useWheel($event.deltaY, plusHour, minusHour)">
          <v-icon @click="minusHour" class="timepicker-icons"> mdi-menu-up</v-icon>

          <div @click="() => clickHour(item)" class="timepicker-items" :key="index" v-for="(item, index) in showedHour">
            {{ item }}
          </div>

          <v-icon @click="plusHour" class="timepicker-icons"> mdi-menu-down</v-icon>
        </v-col>

        <v-col cols="6" @wheel.prevent="useWheel($event.deltaY, plusMinutes, minusMinutes)">
          <v-icon @click="minusMinutes" class="timepicker-icons"> mdi-menu-up</v-icon>

          <div
            @click="() => clickMinutes(item)"
            class="timepicker-items"
            :key="index"
            v-for="(item, index) in showedMinutes"
          >
            {{ item }}
          </div>

          <v-icon @click="plusMinutes" class="timepicker-icons"> mdi-menu-down</v-icon>
        </v-col>
      </v-row>

      <v-row justify="center" class="timepicker-btn">
        <v-btn @click="onClear" text color="warning">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
import { generateTimeArrayHelper } from '@/lib/helpers/generateTimeArray.helper';
import { vMaska } from 'maska';
import { debounceHelper } from '@/lib/helpers/debounceHelper';
import { TouchDeviceMixin } from '@/lib/mixins/TouchDeviceMixin';

export default {
  mixins: [TouchDeviceMixin],
  props: ['value', 'inputAttrs', 'inputListeners', 'disabled'],

  data: () => ({
    menu: false,
    items: generateTimeArrayHelper(),
  }),

  directives: { maska: vMaska },

  computed: {
    mClass() {
      return ['m-input', this.disabled ? 'm-disabled' : ''].join(' ').trim();
    },
    showedHour() {
      const hour = this.value.split(':')[0] || '00';
      return this.showed(this.items.hours, hour);
    },

    showedMinutes() {
      const minutes = this.value.split(':')[1] || '00';
      return this.showed(this.items.minutes, minutes);
    },

    time: {
      get() {
        return this.value;
      },
      set(v) {
        this.debounced(v);
      },
    },
  },
  watch: {
    disabled() {
      this.menu = false;
    },
  },

  methods: {
    useWheel(pos, minus, plus) {
      if (pos < 0) plus();
      else minus();
    },

    showed(array, item) {
      let result = [];
      const index = array.indexOf(item);
      switch (index) {
        case -1:
          result.push(array[array.length - 2]);
          result.push(array[array.length - 1]);
          result.push(array[0]);
          result.push(array[1]);
          result.push(array[2]);
          break;
        case 0:
          result.push(array[array.length - 2]);
          result.push(array[array.length - 1]);
          result.push(array[index]);
          result.push(array[index + 1]);
          result.push(array[index + 2]);
          break;
        case 1:
          result.push(array[array.length - 1]);
          result.push(array[index - 1]);
          result.push(array[index]);
          result.push(array[index + 1]);
          result.push(array[index + 2]);
          break;
        case array.length - 1:
          result.push(array[index - 2]);
          result.push(array[index - 1]);
          result.push(array[index]);
          result.push(array[0]);
          result.push(array[1]);
          break;
        case array.length - 2:
          result.push(array[index - 2]);
          result.push(array[index - 1]);
          result.push(array[index]);
          result.push(array[index + 1]);
          result.push(array[0]);
          break;
        default:
          result.push(array[index - 2]);
          result.push(array[index - 1]);
          result.push(array[index]);
          result.push(array[index + 1]);
          result.push(array[index + 2]);
          break;
      }

      return result;
    },
    clickHour(item) {
      const time = this.value !== '' ? this.value : '00:00';
      const newTime = item + time.slice(-3);
      this.$emit('input', newTime);
    },
    clickMinutes(item) {
      const time = this.value !== '' ? this.value : '00:00';
      const newTime = time.slice(0, 3) + item;
      this.$emit('input', newTime);
    },
    plusHour() {
      const time = this.time.split(':');
      const hour = time[0] || '00';
      const minutes = time[1] || '00';
      const index = this.items.hours.indexOf(hour);
      const newHour = this.items.hours[index + 1] || this.items.hours[0];
      const newTime = newHour + ':' + minutes;
      this.$emit('input', newTime);
    },
    plusMinutes() {
      const time = this.time.split(':');
      const hour = time[0] || '00';
      const minutes = time[1] || '00';
      const index = this.items.minutes.indexOf(minutes);
      const newMinutes = this.items.minutes[index + 1] || this.items.minutes[0];
      const newTime = hour + ':' + newMinutes;
      this.$emit('input', newTime);
    },
    minusHour() {
      const time = this.time.split(':');
      const hour = time[0] || '00';
      const minutes = time[1] || '00';
      const index = this.items.hours.indexOf(hour);
      const newHour = this.items.hours[index - 1] || this.items.hours[this.items.hours.length - 1];
      const newTime = newHour + ':' + minutes;
      this.$emit('input', newTime);
    },
    minusMinutes() {
      const time = this.time.split(':');
      const hour = time[0] || '00';
      const minutes = time[1] || '00';
      const index = this.items.minutes.indexOf(minutes);
      const newMinutes = this.items.minutes[index - 1] || this.items.minutes[this.items.minutes.length - 1];
      const newTime = hour + ':' + newMinutes;
      this.$emit('input', newTime);
    },
    onInputTime(v) {
      if (v === '') {
        this.debounced(v);
        return;
      }
      const time = v.split(':');

      if (+time[0] > 23) time[0] = '00';
      else {
        if (time[0].length !== 2) {
          time[0] = '0' + time[0];
        }
      }

      if (+time[1] > 59) time[1] = '00';
      else {
        if (time[1] === undefined) {
          time[1] = '00';
        } else {
          if (time[1].length !== 2) {
            time[1] = '0' + time[1];
          }
        }
      }
      if (+time[0] === 0) time[0] = '00';
      if (+time[1] === 0) time[1] = '00';
      this.debounced(time.join(':'));
    },
    onClear() {
      this.$refs.menu.save();
      if (this.value !== '') this.$emit('input', '');
    },
  },
  created() {
    this.debounced = debounceHelper((value) => {
      this.time !== value && this.$emit('input', value);
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
.timepicker-box {
  padding: 12px 0;
  max-width: 104px;
}

.timepicker-container {
  padding: 4px;
  font-size: 14px;
  overflow: hidden;
  margin: 0;
  text-align: center;
}

.timepicker-items {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-left: 1px;
  margin-top: 3px;
  margin-bottom: 3px;

  &:hover {
    cursor: pointer;
    background-color: #e0e0e0;
  }

  &:nth-child(4) {
    background-color: #04a9f5 !important;
    color: #fff;
  }
}

.timepicker-item::after {
  content: ':';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
}

.timepicker-btn {
  margin-bottom: 5px;
}

.m-input {
  height: 40px;
  padding: 8px 12px 8px;
  color: rgba(0, 0, 0, 0.87);
  background-color: #f5f7fa !important;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    border: 1px solid #000;
  }
}

.m-disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.38);
  border: 1px solid #69686861;
}
</style>
