import { resetTransactionHelper } from '@/lib/helpers/resetTransactionHelper';
import http from '@/plugins/axios';

export const checkQr = (qr) => {
  return http.get('/order', {
    params: { qr },
  });
};

export const cancelOrder = (transaction_id) =>
  http.post('/cancel_task', {
    transaction_id,
  });

export const startOrder = ({ qr, products, count, signal }) =>
  http
    .post(
      '/order',
      {
        qr,
        products,
      },
      {
        signal,
      }
    )
    .catch(async (er) => {
      let countError = count || 0;
      if (er.responseCode === 21) {
        await cancelOrder();
        countError += 1;
        if (countError === 3) {
          resetTransactionHelper(er.responseCode || 22);
          return;
        }
        return startOrder({ qr, products, countError, signal });
      }
    });

export const closeOrder = ({ qr, products, transaction_id, signal }) => {
  const data = new FormData();
  data.append('order', JSON.stringify({ qr, products, transaction_id }));
  return http.put('/order', data, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
    signal,
  });
};

export default {
  checkQr,
  cancelOrder,
  startOrder,
  closeOrder,
};
