import DateLib from '@/lib/Date';
import Vue from 'vue';

const _format = (v) => (v > 9 ? v : `0${v}`);

export const dateParse = (date) => {
  return DateLib(date);
};
export const dateFilter = (date) => {
  if (date === null) return '';
  return DateLib(date).format('DD.MM.YYYY');
};

export const dateTimeFilter = (date) => {
  if (date === null) return '';
  const _date = new Date(date.replace(' ', 'T'));

  const days = _format(_date.getDate());
  const month = _format(_date.getMonth() + 1);
  const hours = _format(_date.getHours());
  const minutes = _format(_date.getMinutes());

  const result = [days, month, _date.getFullYear()];

  return `${result.join('.')} ${hours}:${minutes}`;
};
Vue.filter('date', dateFilter);
Vue.filter('datetime', dateTimeFilter);

export default {
  dateFilter,
};
