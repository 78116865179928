<template>
  <v-col class="mw-50 card-content">
    <v-card outlined>
      <v-card-title class="m-card-title">
        <v-col>
          <v-row align="center" v-if="role === 'operator'">
            <v-icon class="m-icon"> mdi-television</v-icon>

            <span class="font-weight-regular mr-2 head-title-mobile">
              &nbsp;&ndash;&nbsp;{{ $t(`profileCard.${userPermission}.registerVisits`) }}:
            </span>

            <v-alert color="success" dense dark class="font-weight-regular body-1 py-0 my-0 head-title-mobile">
              {{ pointsTitle }}
            </v-alert>
          </v-row>

          <v-row align="center">
            <v-icon class="m-icon"> mdi-account-outline</v-icon>

            <span class="font-weight-regular mr-2 head-title-mobile">
              &nbsp;&ndash;&nbsp; {{ $t(`profileCard.${userPermission}.profileData`) }}:
            </span>

            <v-alert color="primary" dense dark class="font-weight-regular body-1 py-0 my-0 head-title-mobile">
              {{ name }}
            </v-alert>
          </v-row>
        </v-col>
      </v-card-title>
    </v-card>
  </v-col>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useProfileStore } from '@/store/profile.store';
import { useAppSettingsStore } from '@/store/settings.store';

export default {
  computed: {
    ...mapState(useProfileStore, ['name', 'pointsTitle', 'isAuthenticated', 'userPermission']),
    ...mapState(useAppSettingsStore, ['locale']),
    ...mapState(useProfileStore, ['role']),
  },

  methods: {
    ...mapActions(useProfileStore, ['getProfileData']),
  },

  watch: {
    locale() {
      this.getProfileData();
    },
  },
};
</script>

<style scoped>
.mx-400 {
  max-width: 1200px;
}

.mw-50 {
  min-width: 50%;
  width: max-content;
}

@media screen and (max-width: 568px) {
  .card-content {
    width: 100%;
    padding: 6px 0;
  }

  .m-card-title {
    padding: 6px;
  }
}
</style>
