<template>
  <v-row justify="center" class="mx-0 mb-4">
    <v-btn :disabled="disabled" @click="changeLanguage" text :loading="loading">
      <v-icon :class="mini ? '' : 'mr-2'"> mdi-earth</v-icon>

      <span v-show="!mini">{{ $i18n.locale }}</span>
    </v-btn>
  </v-row>
</template>

<script>
import { mapActions } from 'pinia';
import { useAppSettingsStore } from '@/store/settings.store';

const disabledRoutes = ['transaction', 'order'];

export default {
  props: ['mini'],

  data: () => ({
    loading: false,
  }),

  methods: {
    ...mapActions(useAppSettingsStore, ['setLocale']),

    async changeLanguage() {
      const language = this.$i18n.locale === 'en' ? 'ru' : 'en';
      this.loading = true;
      this.setLocale(language).finally(() => (this.loading = false));
      this.$i18n.locale = language;
    },
  },

  computed: {
    disabled() {
      const routeName = this.$route.name;
      return disabledRoutes.includes(routeName);
    },
  },
};
</script>
