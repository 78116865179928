<template>
  <v-navigation-drawer
    app
    color="bars"
    dark
    :mini-variant.sync="mini"
    :expand-on-hover="isBlocked"
    :permanent="!isMobileSize"
    v-model="show"
  >
    <template v-slot:prepend>
      <NavbarHeadVue @update:drawner="closeMenu" v-model="isBlocked" />
    </template>

    <v-row justify="center" class="my-4">
      <v-img src="@/assets/images/navbar/menu-btn.svg" min-width="150" max-width="150" contain v-show="!mini" />
    </v-row>

    <NavbarNavigation :mini="mini" />

    <template v-slot:append>
      <LanguageToggle :mini="mini" />
      <DeviceToggle :mini="mini" />
      <NavbarFooter class="mt-auto" :mini="mini" />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapWritableState } from 'pinia';
import { useAppSettingsStore } from '@/store/settings.store';

import NavbarHeadVue from './NavbarHead.vue';
import NavbarFooter from './NavbarFooter.vue';
import NavbarNavigation from './NavbarNavigation.vue';
import LanguageToggle from './LanguageToggle.vue';
import { MobileSizeMixin } from '@/lib/mixins/MobileSizeMixin';
import DeviceToggle from '@/layouts/main/components/navbar/DeviceToggle.vue';

export default {
  mixins: [MobileSizeMixin],
  components: {
    DeviceToggle,
    NavbarHeadVue,
    NavbarFooter,
    NavbarNavigation,
    LanguageToggle,
  },
  props: ['drawner'],
  emits: ['update:drawner'],
  data: () => ({
    mini: false,
    show: false,
    isBlocked: false,
  }),

  watch: {
    drawner(v) {
      this.show = v;
    },
    show(v) {
      this.$emit('update:drawner', v);
    },
  },

  computed: {
    ...mapWritableState(useAppSettingsStore, ['isNavBlocked']),
  },
  methods: {
    closeMenu() {
      this.show = false;
    },
  },

  created() {
    if (this.isNavBlocked === undefined || this.isMobileSize) {
      this.isBlocked = this.isMobileSize ? false : !this.blocked;
      this.mini = this.isBlocked;
    } else {
      this.isBlocked = this.isNavBlocked;
      this.mini = this.isNavBlocked;
    }
  },
};
</script>
